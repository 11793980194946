import { render, staticRenderFns } from "./CertificadoAlumno.vue?vue&type=template&id=0e48522a&scoped=true&"
import script from "./CertificadoAlumno.vue?vue&type=script&lang=js&"
export * from "./CertificadoAlumno.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e48522a",
  null
  
)

export default component.exports