<template>

    <div>
        <b-overlay :show="loading">
            <b-card>
                <app-collapse>
                    <app-collapse-item title="Búsqueda"
                                       :is-visible="isVisible">
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                >
                                    <h5 class="font-weight-bold">
                                        Ficha
                                    </h5>
                                    <b-form-input
                                            id="ficha"
                                            v-model="filter.cardNumber"
                                            placeholder="N° de Ficha"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                >
                                    <h5 class="font-weight-bold">
                                        Orden de Compra
                                    </h5>
                                    <b-form-input
                                            id="ordenCompra"
                                            v-model="filter.buyOrderNumber"
                                            placeholder="N° de Orden de Compra"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="6">
                                <b-form-group
                                >
                                    <h5 class="font-weight-bold">
                                        Rut / Nombre / Apellidos
                                    </h5>

                                    <b-form-input
                                            id="alumno"
                                            v-model="filter.alumno"
                                            placeholder="Escriba el dato del Alumno a buscar"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col sm="6" md="6">
                                <b-form-group>
                                    <h5 class="font-weight-bold">
                                       Por
                                    </h5>
                                    <div class="d-flex mt-1">

                                        <b-form-radio
                                                v-model="approved"
                                                name="type"
                                                :value="null"
                                                class="custom-control-primary mr-1"
                                        >
                                            Todos
                                        </b-form-radio>
                                        <b-form-radio
                                                v-model="approved"
                                                name="type"
                                                :value="true"
                                                class="custom-control-primary mr-1"
                                        >
                                            Aprobación
                                        </b-form-radio>
                                        <b-form-radio
                                                v-model="approved"
                                                name="type"
                                                :value="false"
                                                class="custom-control-primary"
                                        >
                                            Participación
                                        </b-form-radio>
                                    </div>
                                </b-form-group>

                            </b-col>
                            <b-col>
                                <div class="text-right mt-2">
                                    <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-primary"
                                            type="submit"
                                            @click="buscar_alumnos(filter)">
                                        <feather-icon icon="SearchIcon" class="mr-50"/>
                                        <span class="align-middle">Buscar</span>
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-card>

            <b-card>
                <h4 class="card-title">Certificado de Alumno</h4>
                <b-row class="pr-1 pl-1">
                    <b-col sm="12" md="12" class="my-1">
                        <div class="d-flex justify-content-end">
                            <b-button
                                    v-b-tooltip.hover.top.v-primary="'En esta opción podrás generar un certificado desde 0, sin datos de cursos ni del alumno. Esta es una funcionalidad para casos especiales de alumnos los cuales no tenemos datos de ellos, pero aún así es necesario obtener un diploma para ellos.'"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="outline-primary"
                                    type="button"
                                    class="ml-1"
                                    @click="exportP"
                            >
                                <feather-icon
                                        icon="AwardIcon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Personalizar</span>

                            </b-button>
                            <b-button
                                    v-b-tooltip.hover.top.v-primary="'Eliminar Certificado'"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="outline-primary"
                                    type="button"
                                    class="ml-1"
                                    @click="deleteTo"
                                    :disabled="totalRows==0"
                            >
                                <feather-icon
                                        icon="Trash2Icon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Eliminar</span>

                            </b-button>
                            <b-button
                                    v-b-tooltip.hover.top.v-primary="'Generar Certificado'"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="outline-primary"
                                    type="button"
                                    class="ml-1"
                                    @click="exportTo"
                                    :disabled="totalRows==0"
                            >
                                <feather-icon
                                        icon="DownloadIcon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Generar</span>

                            </b-button>
                        </div>
                    </b-col>
                    <b-col sm="4" md="4" class="my-1 text-right">
                        <div class="d-flex align-items-center mb-1 mb-md-0">
                            <label>Mostrar</label>
                            <b-form-select
                                    @change="onChangePerPage"
                                    id="perPageSelect"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                    class="mx-50 col-md-3"
                            ></b-form-select>
                            <label>filas por página</label>
                        </div>
                    </b-col>
                    <b-col cols="4" class="mt-2">
                        <div v-if="totalRows > 0">
                            <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                        </div>
                    </b-col>

                    <b-col cols="4">
                        <b-pagination
                                @change="onPageChange"
                                :total-rows="totalRows"
                                :value="page"
                                :per-page="perPage"
                                align="right"
                                class="mt-2 mr-1"
                                aria-controls="my-table"
                        />
                    </b-col>
                </b-row>
                <div class="d-none">{{listado, reLoading}}</div>
                <b-col cols="12" v-if="showTable">
                    <b-table
                            striped
                            hover
                            selectable
                            small
                            :per-page="perPage"
                            :items="list"
                            :fields="fields"
                            responsive
                            class="mb-0"
                            id="my-table"
                            ref="table"
                            show-empty
                            select-mode="multi"
                    >

                        <template #head(select)="data">

                            <div class="text-center">

                                <b-form-checkbox
                                        v-model="allSelected"
                                        @change="toggleAll"
                                />
                            </div>
                        </template>
                        <template #cell(select)="data">
                            <div class="text-center">
                                <b-form-checkbox
                                        v-model="selected"
                                        name="checkbox"
                                        :value="data.value"
                                />
                            </div>
                        </template>
                        <template #cell()="data">
                            {{ data.value ? data.value : '-' }}
                        </template>
                        <template #cell(urlCertificado)="data">
                            <div v-if="data.item.urlCertificado == 'Sin certificados'">
                                Sin certificado
                            </div>
                            <div v-else>
                                <b-link
                                        class="ml-25"
                                        :href="data.item.urlCertificado"
                                        target="_blank"
                                >Ver Certificado
                                </b-link>
                            </div>

                        </template>
                        <template #cell(nota_final)="data">
                            <b-badge class="center" :id="'tooltip-'+data.item.codigo_validez"
                                     :variant="estado_evaluacion(data.item.estado_evaluacion)">{{ data.item.nota_final ?
                                data.item.nota_final : '-'}}
                            </b-badge>
                            <b-tooltip v-if="data.item.estado_evaluacion != null" :target="'tooltip-'+data.item.codigo_validez" triggers="hover"
                                       :variant="estado_evaluacion(data.item.estado_evaluacion)">
                                {{data.item.estado_evaluacion}}
                            </b-tooltip>
                        </template>
                        <template #cell(porciento_avance)="data">
                           <div v-if="data.item.porciento_avance != null">
                               {{data.item.porciento_avance}}%
                           </div>
                            <div v-else>
                               -
                           </div>
                        </template>
                        <template #empty="scope">
                            <div class="text-center">No existen resultados</div>
                        </template>
                    </b-table>
                </b-col>
            </b-card>
        </b-overlay>

        <b-modal ref="modalExport"
                 title="Información"
                 ok-only
                 ok-title="Cerrar">
            <p class="my-4"><b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe</p>
        </b-modal>
    </div>
</template>

<script>

    import {
        BTable,
        BProgress,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BFormSelect,
        BTfoot,
        BTr,
        BTh,
        BFormCheckbox,
        BButton,
        BModal,
        BLink,
        BTooltip,
        BFormRadio,
        VBTooltip,
    } from "bootstrap-vue";
    import Ripple from "vue-ripple-directive";
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import ToastificationContent from "@core/components/toastification/ToastificationContent";

    export default {
        name: "CertificadoAlumno",
        components: {
            BTable,
            BProgress,
            BBadge,
            BPagination,
            BRow,
            BCol,
            BFormSelect,
            BTfoot,
            BTr,
            BTh,
            BFormCheckbox,
            BButton,
            BModal,
            AppCollapseItem,
            AppCollapse,
            BLink,
            BTooltip,
            BFormRadio
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple
        },
        data() {
            return {
                //loading: false,
                showTable: true,
                perPage: 500,
                page: 1,
                dir: false,
                filter: {
                    cardNumber: null,
                    buyOrderNumber: null,
                    alumno: null,
                    fields: []
                },
                isVisible: true,
                allSelected: false,
                selected: [],
                fields: [
                    {key: "select", label: "Marcar"},
                    {key: "rut", label: "Rut"},
                    {key: "nombre", label: "Nombre"},
                    {key: "apellidos", label: "Apellidos"},
                    {key: "empresa", label: "Empresa"},
                    {key: "ficha", label: "Ficha"},
                    {key: "ordenCompra", label: "Orden Compra"},
                    {key: "porciento_avance", label: "% Avance", tdClass: 'text-center', thClass: 'text-center'},
                    {key: "nota_final", label: "Nota Final", tdClass: 'text-center', thClass: 'text-center'},
                    {key: "urlCertificado", label: "Certificado"},
                ],
                list: [],
                pageOptions: [500],
                aprobados: 1,
                approved: null,
                aprobadosText: 'Todos',
            };
        },
        methods: {
            toggleAll(checked) {
                this.selected = []
                if (checked) {
                    this.list.map(item => (
                        this.selected.push(item.codigo_validez)
                    ))
                } else {
                    this.selected = []
                }
            },
            buscar_alumnos(filter) {
                this.filter = filter;
                this.getList();
            },
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            estado_evaluacion(value) {
                if (value == 'Aprobado') {
                    return 'success'
                } else if (value == 'No iniciado') {
                    return 'info'
                } else if (value == 'No aplica') {
                    return 'dark'
                } else if (value == 'Sin nota') {
                    return 'secondary'
                } else if (value == 'Reprobado' || value == 'Reprobado sin nota') {
                    return 'danger'
                }
            },
            getList() {
                let filterData = {
                    "num_ficha": this.filter.cardNumber,
                    "num_orden_compra": this.filter.buyOrderNumber,
                    "sort_by": this.sortField,
                    "page": this.page,
                    "perPage": this.perPage,
                    "alumno": this.filter.alumno,
                    "approved": this.approved
                };
                if ((filterData.num_ficha != null && filterData.num_ficha.length > 0) || (filterData.num_orden_compra != null && filterData.num_orden_compra.length > 0)) {
                    this.$store.dispatch("certificadoAlumno/getCertificadoAlumnos", filterData);
                } else {
                    this.showToast('warning', 'El número de ficha u orden de compra es requerido.');
                }
            },
            deleteTo() {
                if (this.selected.length == 0) {
                    this.showToast('warning', 'Debe seleccionar al menos un certificado');
                    return
                }
                this.$bvModal.msgBoxConfirm('Se eliminarán los Certificados seleccionados!!', {
                    title: '¿Está seguro?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Si, eliminar',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-primary',
                    hideHeaderClose: false,
                    centered: true,
                })
                    .then(value => {
                        if (value) {
                            let data = {
                                "ids_detalle_alumnos": this.selected
                            };
                            this.$store.dispatch("certificadoAlumno/deleteCertificadoAlumnos", data);
                        }
                    })

            },
            exportTo() {
                let filterData = {
                    "num_ficha": this.filter.cardNumber,
                    "num_orden_compra": this.filter.buyOrderNumber,
                    "sort_by": this.sortField,
                    "page": this.page,
                    "perPage": this.perPage,
                    "dtls": this.selected,
                    "approved": this.approved
                };
                if (this.listado.length != 0 && (filterData.num_ficha != null && filterData.num_ficha.length > 0) || (filterData.num_orden_compra != null && filterData.num_orden_compra.length > 0)) {

                    this.$store.dispatch("certificadoAlumno/exportCertificadoAlumnos", filterData);
                    this.selected = []

                } else {
                    this.showToast('warning', 'No se encontraron datos para exportar.');
                }
            },
            exportP() {
                this.$router.push({ name: "generar_certificado" });
            },
            showToast(variant, mensaje) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notificación',
                        icon: 'BellIcon',
                        text: mensaje,
                        variant,
                    },
                })
            },
        },
        computed: {
            totalRows() {
                return this.$store.state.certificadoAlumno.rowsTotal;
            },
            to() {
                return this.$store.state.certificadoAlumno.to;
            },
            from() {
                return this.$store.state.certificadoAlumno.from;
            },
            listado() {
                const data = this.$store.state.certificadoAlumno.list
                this.list = []
                for (const key in data) {
                    const row = {
                        codigo_validez: data[key].codigo_validez,
                        rut: data[key].rut,
                        nombre: data[key].nombre,
                        apellidos: data[key].apellidos,
                        empresa: data[key].empresa,
                        ficha: data[key].ficha,
                        ordenCompra: data[key].ordenCompra,
                        urlCertificado: data[key].urlCertificado,
                        select: data[key].codigo_validez,
                        nota_final: data[key].nota_final,
                        porciento_avance: data[key].porciento_avance,
                        estado_evaluacion: data[key].estado_evaluacion,
                    };
                    this.list.push(row)
                }
                return this.list
            },
            currentPage() {
                return this.$store.state.certificadoAlumno.currentPage;
            },
            loading() {
                return this.$store.state.certificadoAlumno.loading;
            },
            reLoading() {
                let re = this.$store.state.certificadoAlumno.reLoading;
                if (re) {
                    this.getList()
                    this.selected = []
                    this.$store.commit('certificadoAlumno/setReLoading', false)
                    this.showToast('Información', 'Los certificados se han eliminado correctamente');
                }
            },
            notification() {
                return this.$store.state.certificadoAlumno.notification;
            }


        },
        watch: {
            notification: function (value) {
                switch (value.from) {
                    case "exportCertificadoAlumnos":
                        if (value.type == "error") {
                            this.showToast('warning', value.message);
                        } else {
                            this.$refs.modalExport.show();
                        }

                        break;
                    case "getCertificadoAlumnos":
                        if (value.type == "error") {
                            this.showToast('warning', value.message);
                        }
                        break;

                }


            }
        }
    };
</script>

<style scoped>

</style>
